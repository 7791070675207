export enum Routes {
  Root = "/",
  Report = "/report",
  Version = "/versions/:versionId",
  App = "/apps/:appId",
  Web2App = "/web2app",
  Dashboard = "/web2app/:appId",
  Plans = "/plans",
  Reports = "/apps",
  Updates = "/versions",
  Ios = "/ios-apps",
  Settings = "/settings",
  Login = "/auth/login",
  Register = "/auth/register",
  Recovery = "/auth/recovery",
  ChangePassword = "/auth/change-password",
  Verification = "/auth/verification",
  Error = "/error",
}
