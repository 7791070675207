import { FC, useEffect } from "react";
import TagManager from "react-gtm-module";
import { GTM_ID, IS_PROD } from "src/constants/environments";

const tagManagerArgs = {
  gtmId: GTM_ID,
};

export const GTMInitializer: FC = () => {
  useEffect(() => {
    if (IS_PROD) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return null;
};
