import { jsx as _jsx } from "react/jsx-runtime";
import { Switch as SwitchBase, switchClasses } from "@mui/base";
import { css, styled } from "@mui/material";
const CustomSwitch = styled(SwitchBase)(({ theme }) => css `
  width: 42px;
  height: 26px;
  padding: 0;
  display: flex;
  position: relative;

  & .${switchClasses.thumb} {
    position: absolute;
    left: 2px;
    top: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    width: 22px;
    height: 22px;
    border-radius: 11px;
    transition: 200ms transform;
    background-color: ${theme.palette.background.paper};
  }

  &.${switchClasses.checked} .${switchClasses.thumb} {
    transform: translateX(16px);
  }

  & .${switchClasses.track} {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 13px;
    background-color: ${theme.palette.background.default};
    box-sizing: border-box;
  }

  & .${switchClasses.input} {
    display: none;
  }
`);
export const Switch = (props) => (_jsx(CustomSwitch, { ...props }));
