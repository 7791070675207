import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { mobileAppConnect } from "src/api/mobileAppConnect";
import { CookieNames } from "src/constants/CookieNames";
import { COOKIE_DOMAIN } from "src/constants/environments";
import { QueryKeys } from "src/constants/queryKeys";
import { Routes } from "src/constants/routes";
import { useCheckHasApps } from "src/hooks/useCheckHasApps";
import { useGetCurrentUser } from "src/hooks/useGetCurrentUser";

export const useCheckRequestedAppApp = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const appId = Cookies.get(CookieNames.RequestedAppId);

  const { mutate: connectMobileApp } = useMutation({
    mutationFn: mobileAppConnect,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.MobileApps] });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.CurrentUser] });
    },
  });

  const { data: currentUser } = useGetCurrentUser();
  const { hasApps } = useCheckHasApps();

  useEffect(() => {
    if (!appId || !currentUser || hasApps === undefined) {
      return;
    }

    Cookies.remove(CookieNames.RequestedAppId, { domain: COOKIE_DOMAIN });

    const hasBonusCredits =
      currentUser.bonus_credits && currentUser.bonus_credits > 0;

    if (hasApps === true || !hasBonusCredits) {
      navigate(Routes.App.replace(":appId", appId));
      return;
    }

    connectMobileApp({ mobile_app_id: appId });
  }, [appId, currentUser, hasApps]);
};
