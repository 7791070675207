import { createBrowserRouter } from "react-router-dom";
import { Routes } from "src/constants/routes";
import { AuthLayout } from "src/layouts/AuthLayout/AuthLayout";
import { PrivateLayout } from "src/layouts/PrivateLayout/PrivateLayout";
import { Error } from "src/pages/Error/Error";
import { checkLogout } from "src/utils/checkLogout";

export const router = createBrowserRouter([
  {
    element: <PrivateLayout />,
    path: Routes.Root,
    errorElement: <Error />,
    children: [
      {
        index: true,
        lazy: () => import("src/pages/Reports/Reports"),
      },
      {
        path: Routes.Plans,
        lazy: () => import("src/pages/Plans/Plans"),
      },
      {
        path: Routes.App,
        lazy: () => import("src/pages/ReportApp/ReportApp"),
      },
      {
        path: Routes.Dashboard,
        lazy: () => import("src/pages/Dashboard/Dashboard"),
      },
      {
        path: Routes.Version,
        lazy: () => import("src/pages/ReportVersion/ReportVersion"),
      },
      {
        path: Routes.Updates,
        lazy: () => import("src/pages/Updates/Updates"),
      },
      {
        path: Routes.Ios,
        lazy: () => import("src/pages/Ios/Ios"),
      },
      {
        path: Routes.Settings,
        lazy: () => import("src/pages/Settings/Settings"),
      },
      {
        path: Routes.Web2App,
        lazy: () => import("src/pages/Web2App/Web2App"),
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        loader: checkLogout,
        path: Routes.Login,
        lazy: () => import("src/pages/Auth/Login/Login"),
      },
      {
        loader: checkLogout,
        path: Routes.Register,
        lazy: () => import("src/pages/Auth/Register/Registration"),
      },
      {
        path: Routes.Verification,
        lazy: () => import("src/pages/Auth/Verification/Verification"),
      },
      {
        path: Routes.Recovery,
        lazy: () => import("src/pages/Auth/Recovery/Recovery"),
      },
      {
        path: Routes.ChangePassword,
        lazy: () => import("src/pages/Auth/ChangePassword/ChangePassword"),
      },
    ],
  },
  {
    path: Routes.Error,
    element: <Error />,
  },
  {
    path: "*",
    lazy: () => import("src/pages/NotFound/NotFound"),
  },
]);
