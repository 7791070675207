import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Option as OptionBase } from "@mui/base/Option";
import { Select as SelectBase } from "@mui/base/Select";
import { css, styled } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef } from "react";
import { TypographyType } from "../constants";
import { useTypographyStyles } from "../helpers/useTypographyStyles";
import { ArrowIcon } from "../icons/ArrowIcon";
const Wrapper = styled(Box)(({ theme }) => css `
    position: relative;
    background-color: ${theme.palette.background.paper};
    border-radius: ${theme.spacing(1)};
  `);
const StyledSelect = styled(SelectBase)(({ theme }) => css `
    ${useTypographyStyles(TypographyType.Body1)}
    font-family: inherit;
    color: ${theme.palette.text.header};
    text-align: left;

    position: relative;
    z-index: 1;
    padding: ${theme.spacing(2)};
    padding-right: ${theme.spacing(8)};
    border: 1px solid ${theme.palette.divider};
    background-color: transparent;
    border-radius: ${theme.spacing(1)};
    cursor: pointer;
  `);
const StyledListbox = styled("ul")(({ theme }) => css `
    font-family: inherit;
    margin: 0;
    padding: ${theme.spacing(1)} 0;
    min-width: 100%;
    border: 1px solid ${theme.palette.divider};
    box-shadow: ${theme.shadows[2]};
    background-color: ${theme.palette.background.paper};
    border-radius: ${theme.spacing(1)};
  `);
export const Option = styled(OptionBase)(({ theme }) => css `
    ${useTypographyStyles(TypographyType.Body1)}
    font-family: inherit;
    color: ${theme.palette.text.header};
    list-style: none;
    padding: ${theme.spacing(2)};
    cursor: pointer;

    :hover {
      background-color: ${theme.palette.action.hover};
    }
  `);
export const Select = forwardRef((props, ref) => {
    return (_jsxs(Wrapper, { children: [_jsx(StyledSelect, { slots: {
                    listbox: StyledListbox,
                }, slotProps: {
                    // @ts-expect-error type error
                    root: {
                        style: { width: "100%" },
                    },
                    popup: {
                        disablePortal: true,
                        style: { right: 0 },
                    },
                }, ref: ref, ...props }), _jsx(Box, { sx: {
                    position: "absolute",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                }, children: _jsx(ArrowIcon, { sx: {
                        display: "block",
                        width: 12,
                        height: 12,
                        color: "text.secondary",
                    } }) })] }));
});
