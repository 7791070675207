import { isAxiosError } from "axios";
import { redirect } from "react-router-dom";
import { sessionGet } from "src/api/sessionGet";
import { queryClient } from "src/configs/queryClient";
import { QueryKeys } from "src/constants/queryKeys";
import { Routes } from "src/constants/routes";
import { StatusCodes } from "src/constants/statuses";

export const checkLogout = async () => {
  try {
    await queryClient.fetchQuery({
      queryKey: [QueryKeys.WhoAmI],
      queryFn: () => sessionGet(),
      retry: (_, error) => {
        if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
          return false;
        }
        return true;
      },
    });
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response?.status === StatusCodes.BAD_REQUEST
    ) {
      throw redirect(Routes.Root);
    }
    return true;
  }

  throw redirect(Routes.Root);
};
