import {
  Box,
  BurgerIcon,
  css,
  Grid,
  Menu,
  styled,
  useMediaQuery,
  useTheme,
} from "@flowriver/ui-kit";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "src/assets/logo.svg";
import { ViewMode } from "src/components/Desk/constants";
import { useDeskStore } from "src/components/Desk/store/useDeskStore";
import { PlansBanner } from "src/components/PlansBanner/PlansBanner";
import { Routes } from "src/constants/routes";
import { AppMenuItem } from "src/layouts/PrivateLayout/components/AppMenuItem";
import {
  SIDE_MENU_COLLAPSED_WIDTH,
  SIDE_MENU_WIDTH,
} from "src/layouts/PrivateLayout/constants";
import { useGetTabs } from "src/layouts/PrivateLayout/hooks/useGetTabs";

const Wrapper = styled("section")(
  ({ theme }) => css`
    position: fixed;
    height: 100vh;
    background-color: ${theme.palette.common.softBlack};
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border-right: 1px solid ${theme.palette.divider};
    overflow: hidden;
    z-index: 10;
    transition: 0.3s ease-in-out transform;
  `,
);

const BackDrop = styled("div")(css`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.3s ease-in-out opacity;
`);

const BurgerButton = styled("button")(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${theme.palette.common.softBlack};
    width: ${SIDE_MENU_COLLAPSED_WIDTH}px;
    height: ${SIDE_MENU_COLLAPSED_WIDTH}px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

export const SideMenu = () => {
  const tabs = useGetTabs();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const viewMode = useDeskStore((state) => state.viewMode);

  const [manualyCollapsed, setManualyCollapsed] = useState(true);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const isCollapsed = isMobile && manualyCollapsed;

  const getWidth = () => {
    return isMobile ? SIDE_MENU_COLLAPSED_WIDTH : SIDE_MENU_WIDTH;
  };

  useEffect(() => {
    if (!isMobile) {
      setShowBackdrop(false);
      setManualyCollapsed(true);
    }
  }, [isMobile]);

  const handleToggle = () => {
    setManualyCollapsed((collapsed) => {
      if (collapsed) {
        setShowBackdrop(true);
      } else {
        setTimeout(() => {
          setShowBackdrop(false);
        }, 300);
      }

      return !collapsed;
    });
  };

  const handleLinkClick = () => {
    setShowBackdrop(false);
    setManualyCollapsed(true);
  };

  return (
    <Wrapper
      sx={{
        width: getWidth(),
        display: viewMode === ViewMode.Desk ? "none" : "flex",
      }}
    >
      {isMobile && (
        <BurgerButton onClick={handleToggle}>
          <BurgerIcon color="secondary" />
        </BurgerButton>
      )}
      {showBackdrop && (
        <BackDrop
          onClick={handleToggle}
          sx={{ opacity: isCollapsed ? 0 : 1 }}
        />
      )}
      <Wrapper
        sx={{
          width: SIDE_MENU_WIDTH,
          py: 6,
          transform: isCollapsed ? "translate(-100%)" : "translate(0)",
        }}
      >
        <Grid mb={12} px={5} container>
          <Grid px={4} sx={{ ":hover": { opacity: 0.5 } }}>
            <Box
              component={Link}
              to={Routes.Root}
              onClick={handleLinkClick}
              sx={{ textDecoration: "none" }}
            >
              <Box component="span" display="flex" alignItems="center">
                <img src={logo} width={126} height={26} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container flexDirection="column" width="100%">
          <Grid pb={9} px={5} borderBottom={1} borderColor="common.darkGray2">
            <Menu>
              {tabs.map(({ label, link, icon, isBeta, isNew }, index) => (
                <AppMenuItem
                  key={`tab-${index}`}
                  label={label}
                  link={link}
                  icon={icon}
                  isBeta={isBeta}
                  isNew={isNew}
                  onClick={handleLinkClick}
                />
              ))}
            </Menu>
          </Grid>
          <Grid></Grid>
        </Grid>
        <Grid container mt="110px" justifyContent="center">
          <PlansBanner />
        </Grid>
      </Wrapper>
    </Wrapper>
  );
};
