import { FC } from "react";
import { Content } from "src/layouts/PrivateLayout/components/Content";
import { useCheckAuth } from "src/layouts/PrivateLayout/hooks/useCheckAuth";

export const PrivateLayout: FC = () => {
  const isAuth = useCheckAuth();

  if (!isAuth) {
    return null;
  }

  return <Content />;
};
