import { AnalyticsProvider } from "@flowriver/analytics";
import { CssBaseline } from "@flowriver/ui-kit";
import { QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { GTMInitializer } from "src/components/GTMInitializer/GTMInitializer";
import { ThemeSwitchProvider } from "src/components/ThemeSwitchProvider";
import { queryClient } from "src/configs/queryClient";
import { router } from "src/configs/router";
import { AMPLITUDE_API_KEY, IS_PROD } from "src/constants/environments";

import("src/configs/sentry");
import "@xyflow/react/dist/style.css";
import "src/configs/i18n";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    {IS_PROD ? <GTMInitializer /> : null}

    <ThemeSwitchProvider>
      <QueryClientProvider client={queryClient}>
        <AnalyticsProvider
          amplitudeApiKey={AMPLITUDE_API_KEY}
          isProduction={IS_PROD}
        >
          <CssBaseline />
          <RouterProvider router={router} />
          <SnackbarProvider />
        </AnalyticsProvider>
      </QueryClientProvider>
    </ThemeSwitchProvider>
  </React.StrictMode>,
);
