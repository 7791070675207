import { useQuery } from "@tanstack/react-query";
import { mobileAppsGet } from "src/api/mobileAppsGet";
import { QueryKeys } from "src/constants/queryKeys";
import { MobileAppsGetParams } from "src/types/MobileAppsGetParams";

export const useCheckHasApps = (
  filters: MobileAppsGetParams["filters"] = {},
) => {
  const { data: app, isLoading } = useQuery({
    queryFn: () => mobileAppsGet({ per_page: 1, filters }),
    queryKey: [QueryKeys.MobileApps, { per_page: 1, filters }],
    select: (data) => data.data,
  });

  const hasApps = app?.items ? app.items.length > 0 : false;

  return { hasApps: isLoading ? undefined : hasApps, isLoading };
};
