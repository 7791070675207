import { paths } from "@flowriver/schema";
import { MobileAppsGetParams } from "src/types/MobileAppsGetParams";
import { api } from "src/utils/api";

type Response =
  paths["/api/v1/mobile_apps"]["get"]["responses"]["200"]["content"]["application/json"];

export const mobileAppsGet = (params: MobileAppsGetParams) =>
  api.get<Response>("/v1/mobile_apps", {
    params,
  });
