import { commonColors as common } from "./commonColors";
import { ThemeMode } from "./ThemeMode";
export const lightPalette = {
    mode: ThemeMode.light,
    primary: {
        main: common.blue,
        contrastText: common.white,
        light: common.blue,
        dark: common.black,
        gradient: common.gradient,
        disabled: common.blueDust,
    },
    secondary: {
        main: common.white,
        contrastText: common.white,
        light: common.white,
        dark: common.black,
        gradient: common.gradient,
        disabled: common.softGray,
    },
    dark: {
        main: common.black,
        contrastText: common.white,
        light: common.darkGray2,
        dark: common.black,
        gradient: common.gradient,
        disabled: common.darkGray,
    },
    // TODO remove this from palette
    common,
    background: {
        default: common.softGray,
        secondary: common.gray,
        paper: common.white,
    },
    text: {
        header: common.black,
        primary: common.softBlack,
        secondary: common.darkGray2,
        disabled: common.gray,
        light: common.white,
    },
    error: {
        main: common.red,
        light: common.redLight,
        contrastText: common.white,
    },
    action: {
        active: common.gray,
        hover: common.softGray,
        selected: common.gray,
        disabled: common.gray,
        disabledBackground: common.gray,
        focus: common.gray,
        hoverOpacity: 0.08,
        disabledOpacity: 0.38,
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },
    divider: common.gray,
};
